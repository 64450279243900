<template>
  <main class="container categories">
    <section
      v-if="banner || category?.banner_photo"
      :style="{
        background:
          'url(' + banner ? banner?.banner_photo : category?.banner_photo + ')',
      }"
      class="categories_banner"
      style="position: relative"
    >
      <div style="z-index: 2" class="c_banner_text">
        <p
          v-html="
            banner
              ? banner?.description[localeLang]
              : category?.description[localeLang]
          "
        >
        </p>
        <button
          v-show="
            banner
              ? banner?.banner_button_visible
              : category?.banner_button_visible
          "
          class="banner_btn"
          :style="{
            background: `${
              banner
                ? banner?.banner_button_color
                : category?.banner_button_color
            }`,
          }"
          ><a
            :style="{
              color: `${
                banner
                  ? banner?.banner_button_text_color
                  : category?.banner_button_text_color
              }`,
            }"
            target="_blank"
            :href="
              banner ? banner?.banner_button_link : category?.banner_button_link
            "
            >{{
              banner
                ? banner?.banner_button_text[localeLang]
                : category?.banner_button_text[localeLang]
            }}</a
          >
        </button>
      </div>
      <img
        style="position: absolute"
        :src="banner ? banner?.banner_photo : category?.banner_photo"
        :alt="
          getImageName(banner ? banner?.banner_photo : category?.banner_photo)
        "
        :title="
          getImageName(banner ? banner?.banner_photo : category?.banner_photo)
        "
      />
    </section>

    <section class="d-flex">
      <v-container fluid class="pa-0">
        <v-row no-gutters>
          <v-col v-if="$vuetify.breakpoint.mdAndUp" sm="3" class="block_left">
            <h4 class="p17">Filter by</h4>

            <v-expansion-panels
              style="width: 90%; border: 1px solid #f2f2f2; margin-bottom: 10px"
              flat
            >
              <v-expansion-panel>
                <v-expansion-panel-header class="pl-4 pr-2 py-3">
                  Price
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-range-slider
                    color="#84C897"
                    v-model="maxPriceValue"
                    track-color="'#E8E8E8'"
                    thumb-label="always"
                    :max="maxPrice"
                    hide-details
                    @input="handleSliderChange"
                  ></v-range-slider>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <aside class="nav-bar">
              <div class="nav_close">
                <span class="icon_close_1"></span>
                <span class="icon_close_2"></span>
              </div>
              <div class="cat-">
                <ul
                  class="filter_header pa-0"
                  :class="{ 'mb-2': $vuetify.breakpoint.smAndDown }"
                >
                  <li
                    v-for="categoryItem in retailCategories"
                    :key="categoryItem?.id"
                    :class="{
                      active: $route.params.slug === categoryItem.url,
                    }"
                    class="d-flex align-center f_items nav__link-dropdown"
                  >
                    <span
                      class="nav__link"
                      @click.stop="
                        setCategories(
                          categoryItem.title[localeLang],
                          categoryItem,
                          categoryItem?.id
                        )
                      "
                      >{{ categoryItem?.title?.ru }}</span
                    >
                    <CategoryRecursion
                      @select="
                        (item) => {
                          setCategories(item.title[localeLang], item, item?.id)
                        }
                      "
                      :array="categoryItem.childrens"
                    />
                  </li>
                </ul>
              </div>
              <v-expansion-panels
                style="width: 90%; border: 1px solid #e4f1e3"
                flat
                class="mb-1"
              >
                <v-expansion-panel>
                  <v-expansion-panel-header class="pl-4 pr-2 py-3 icon-title">
                    Special products
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <div class="d-flex flex-column">
                      <div
                        v-for="(category, index) in specialCategories"
                        :key="index"
                        @click="filterBySpecial(category)"
                        style="cursor: pointer"
                        class="option-div"
                      >
                        <span class="options-span">{{
                          category?.title?.ru
                        }}</span>
                      </div>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

              <v-expansion-panels
                style="width: 90%; border: 1px solid #e4f1e3"
                flat
                class="mb-1"
              >
                <v-expansion-panel>
                  <v-expansion-panel-header class="pl-4 pr-2 py-3">
                    Colors
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <div class="d-flex flex-column">
                      <div
                        v-for="(color, index) in colors"
                        :key="index"
                        @click="filterByColor(color)"
                        style="cursor: pointer"
                        class="option-div"
                      >
                        <v-badge
                          dot
                          inline
                          :color="color.css_code"
                          class="mr-3"
                          :class="{ bordered: color.name === 'White' }"
                        ></v-badge>
                        <span class="options-span">{{ color.name }}</span>
                      </div>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <!-- <v-expansion-panels
                style="width: 88%; border: 1px solid #f2f2f2"
                class="mb-1"
                flat
              >
                <v-expansion-panel>
                  <v-expansion-panel-header class="pl-4 pr-2 py-3">
                    Size
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-container class="pa-0 size-filter" fluid>
                      <v-row no-gutters>
                        <v-col cols="6">
                          <div class="d-flex align-center">
                            <input
                              class="size_choice mr-3"
                              type="checkbox"
                            /><span>One-size</span>
                          </div>
                        </v-col>
                        <v-col cols="6">
                          <div class="d-flex align-center">
                            <input type="checkbox" class="size_choice mr-3" />
                            <span>XS</span>
                          </div>
                        </v-col>
                        <v-col cols="6">
                          <div class="d-flex align-center">
                            <input type="checkbox" class="size_choice mr-3" />
                            <span>S</span>
                          </div>
                        </v-col>
                        <v-col cols="6">
                          <div class="d-flex align-center">
                            <input type="checkbox" class="size_choice mr-3" />
                            <span>M</span>
                          </div>
                        </v-col>
                        <v-col cols="6">
                          <div class="d-flex align-center">
                            <input type="checkbox" class="size_choice mr-3" />
                            <span>L</span>
                          </div>
                        </v-col>
                        <v-col cols="6">
                          <div class="d-flex align-center">
                            <input type="checkbox" class="size_choice mr-3" />
                            <span>XL</span>
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels> -->
            </aside>
          </v-col>
          <v-col
            sm="9"
            class="block_right"
            :class="{ 'mx-auto': $vuetify.breakpoint.smAndDown }"
          >
            <v-row no-gutters>
              <v-col
                cols="12"
                class="d-flex nav__menu"
                :class="[
                  $vuetify.breakpoint.smAndDown
                    ? 'flex-column'
                    : 'justify-space-between',
                ]"
              >
                <!-- <v-treeview
                  dense
                  open-all
                  v-if="retailCategories[0].children?.length"
                  :items="retailCategories"
                  activatable
                  selection-type="independent"
                  return-object
                  item-children="childrens"
                >
                  <template v-slot:label="{ item }">
                    <div
                      v-if="item?.children?.length"
                      @click="
                        setCategories(item.title[localeLang], item, item.id)
                      "
                      class="v-treeview-node__label"
                      >{{ item.title.ru }}</div
                    >
                  </template>
                </v-treeview> -->
                <!-- <ul class="pa-0">
                  <li
                    v-for="category in retailCategories"
                    :key="category.id"
                    class="nav__link-dropdown"
                  >
                    {{ category.title.ru }}
                  </li>
                </ul> -->
                <!-- <div v-if="$vuetify.breakpoint.smAndDown"> -->
                <!-- <ul
                  class="filter_header pa-0"
                  :class="{ 'mb-2': $vuetify.breakpoint.smAndDown }"
                >
                  <li
                    v-for="categoryItem in retailCategories"
                    :key="categoryItem?.id"
                    :class="{
                      active: $route.params.slug === categoryItem.url,
                    }"
                    class="d-flex align-center f_items nav__link-dropdown"
                  >
                    <span
                      class="nav__link"
                      @click.stop="
                        setCategories(
                          categoryItem.title[localeLang],
                          categoryItem,
                          categoryItem.id
                        )
                      "
                      >{{ categoryItem.title.ru }}</span
                    >
                    <CategoryRecursion
                      @select="
                        (item) => {
                          setCategories(item.title[localeLang], item, item.id)
                        }
                      "
                      :array="categoryItem.childrens"
                    />
                  </li>
                </ul> -->
                <!-- </div> -->

                <div>
                  <div
                    v-if="$vuetify.breakpoint.smAndDown"
                    class="mb-2 d-flex justify-space-between slider-category"
                  >
                    <v-menu bottom offset-y :close-on-content-click="false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          text
                          style="border: 1px solid #f2f2f2"
                          v-on="on"
                          v-bind="attrs"
                          class="pl-4 pr-2"
                        >
                          <span class="mr-3">Price</span>
                          <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <div class="pt-5">
                        <v-range-slider
                          :thumb-size="15"
                          color="#84C897"
                          v-model="maxPriceValue"
                          track-color="'#E8E8E8'"
                          thumb-label="always"
                          :max="maxPrice"
                          hide-details
                          @input="handleSliderChange"
                        ></v-range-slider>
                      </div>
                    </v-menu>
                    <v-menu bottom offset-y :close-on-content-click="false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          text
                          style="border: 1px solid #f2f2f2"
                          v-on="on"
                          v-bind="attrs"
                          class="pl-4 pr-2"
                        >
                          <span class="mr-3">{{
                            retailCategories[0]?.title?.ru
                          }}</span>
                          <!-- <span class="mr-3">{{ retailCategories}}</span> -->
                          <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-card class="d-flex flex-column">
                        <div style="padding: 10px">
                          <span
                            @click="
                              setCategories(
                                retailCategories[0].title[localeLang],
                                retailCategories[0],
                                retailCategories[0]?.id
                              )
                            "
                            >{{ retailCategories[0]?.title?.ru }}</span
                          >
                        </div>
                      </v-card>
                    </v-menu>

                    <v-menu bottom offset-y :close-on-content-click="false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          text
                          style="border: 1px solid #f2f2f2"
                          v-on="on"
                          v-bind="attrs"
                          class="pl-4 pr-2"
                        >
                          <span class="mr-3">{{
                            retailCategories[1]?.title?.ru
                          }}</span>
                          <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-card class="d-flex flex-column">
                        <div
                          style="padding: 10px"
                          v-for="(item, index) in retailCategories[1].childrens"
                          :key="index"
                        >
                          <span
                            @click="
                              setCategories(
                                item.title[localeLang],
                                item,
                                item?.id
                              )
                            "
                            >{{ item?.title?.ru }}</span
                          >
                        </div>
                      </v-card>
                    </v-menu>

                    <v-menu bottom offset-y :close-on-content-click="false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          text
                          style="border: 1px solid #f2f2f2"
                          v-on="on"
                          v-bind="attrs"
                          class="pl-4 pr-2"
                        >
                          <span class="mr-3">Special products</span>
                          <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-card class="d-flex flex-column">
                        <div
                          style="padding: 10px"
                          v-for="(category, index) in specialCategories"
                          :key="index"
                          @click="filterBySpecial(category)"
                        >
                          <span>{{ category?.title?.ru }}</span>
                        </div>
                      </v-card>
                    </v-menu>
                    <v-menu bottom offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          text
                          style="border: 1px solid #f2f2f2"
                          v-on="on"
                          v-bind="attrs"
                          class="pl-4 pr-2"
                        >
                          <span class="mr-3">Colors</span>
                          <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-card class="d-flex flex-column">
                        <div
                          style="padding: 10px"
                          v-for="(color, index) in colors"
                          :key="index"
                          @click="filterByColor(color)"
                        >
                          <v-badge
                            dot
                            inline
                            :color="color?.css_code"
                            class="mr-3"
                            :class="{ bordered: color?.name === 'White' }"
                          ></v-badge>
                          <span>{{ color?.name }}</span>
                        </div>
                      </v-card>
                    </v-menu>
                  </div>
                  <div class="sort">
                    <!-- <span class="sort_by">Sort by:</span> -->
                    <!-- <v-menu bottom offset-y content-class="sort__menu">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          :elevation="0"
                          style="border: 1px solid #f2f2f2; height: unset"
                          class="px-0"
                        >
                          <span class="mr-5">{{ sortedBy }}</span>
                          <span class="arrow_bottom"></span>
                        </v-btn>
                      </template>
                      <ul class="filter__dropdown-menu d-block pa-0">
                        <li
                          ><a class="menu_items" href="#"
                            >Sort by popularity</a
                          ></li
                        >
                        <li
                          ><a class="menu_items" href="#"
                            >Sort by popularity</a
                          ></li
                        >
                        <li
                          ><a class="menu_items" href="#"
                            >Sort by popularity</a
                          ></li
                        >
                      </ul>
                    </v-menu> -->
                  </div>
                </div>
              </v-col>
            </v-row>
            <div
              class="products"
              :class="{ 'd-flex': $route.query.section !== 'promotions' }"
            >
              <transition name="slide-fade" mode="out-in">
                <v-row :key="$route.query.section" class="list_product mx-n3">
                  <v-col
                    v-for="product in items"
                    :key="product.product?.id"
                    cols="6"
                    md="4"
                    class="pa-3"
                  >
                    <ProductCard
                      :product="product.product ? product.product : product"
                      :price="product.price"
                      :currency="product.currency"
                      @click.native="goToProduct(product)"
                      @goToDetails="goToProduct(product)"
                    />
                  </v-col>
                  <div
                    class="no-items"
                    v-if="items !== null && items.length === 0"
                  >
                    There are no products matching the selected filters
                  </div>
                  <nav
                    v-show="items?.length"
                    class="mb-16 d-flex justify-content-lg-end"
                    aria-label="Page navigation example"
                  >
                    <ul class="pagination w-25">
                      <li
                        v-if="productPagination.prev_page_url"
                        class="page-item"
                      >
                        <a
                          class="page-link"
                          href="#"
                          @click.prevent="
                            fetchProductsPage({
                              id: $route.query.categoryId,
                              url: productPagination.prev_page_url,
                            })
                          "
                          tabindex="-1"
                          >Previous</a
                        >
                      </li>
                      <li
                        v-if="productPagination.next_page_url"
                        class="page-item text-center"
                      >
                        <a
                          class="page-link"
                          @click.prevent="
                            fetchProductsPage({
                              id: $route.query.categoryId,
                              url: productPagination.next_page_url,
                            })
                          "
                          href="#"
                          >Next</a
                        >
                      </li>
                    </ul>
                  </nav>
                </v-row>
              </transition>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </main>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import cloneDeep from 'lodash.clonedeep'
import debounce from 'lodash.debounce'
import ProductCard from '@/view/content/store/ProductCard'
import CategoryRecursion from './CategoryRecursion.vue'
import { getImageName } from '@/utils/utils'
const { mapState: State_Auth } = createNamespacedHelpers('auth')
const { mapActions } = createNamespacedHelpers('storeCart')
const { mapState } = createNamespacedHelpers('storeProducts')
const {
  mapState: State_specialCategories,
  mapActions: Actions_specialCategories,
} = createNamespacedHelpers('specialCategories')
const { mapState: State_products, mapActions: Actions_products } =
  createNamespacedHelpers('products')

const {
  mapState: State_reatailCategories,
  mapActions: Actions_reatailCategories,
  mapMutations: Mutations_retailCategories,
} = createNamespacedHelpers('retailCategories')
export default {
  name: 'Categories',
  components: {
    ProductCard,
    CategoryRecursion,
  },
  data() {
    return {
      min: -50,
      max: 90,
      value: [-20, 70],
      maxPriceValue: [100, 100],
      sortedBy: 'Sort by popularity',
      colorFilterBy: '',
      items: null,
      baseUrl: '',
      banner: undefined,
      colors: [],
    }
  },
  computed: {
    ...State_reatailCategories([
      'retailCategories',
      'category',
      'defaultCategory',
      'categoryColors',
    ]),
    ...State_specialCategories([
      'specialCategories',
      // 'category',
      'defaultCategory',
    ]),
    ...mapState([
      // 'colors',
      'feltShoes',
      'interiorItems',
      'accessories',
      'recommendedItems',
      'bestSellers',
      'collections',
    ]),
    ...State_products(['products', 'productPagination', 'maxPrice']),
    ...State_Auth(['localeLang']),
  },
  watch: {
    products(val) {
      this.items = val
    },
    maxPrice(newVal) {
      this.maxPriceValue = [0, newVal]
    },
  },
  methods: {
    getImageName,

    ...mapActions(['toggleSideCart', 'addCartItem', 'setSelectedProduct']),
    ...Actions_products([
      'searchProducts',
      'getAllProducts',
      'getAllProductsWithFilterPrice',
      'getMaxPrice',
      'productByCatigory',
      'fetchProductsPage',
      'getSpecialProductColorsBySlag',
      'getSpecialProductBySlug',
      'specialProductByCategory',
      'setProductByColor',
    ]),
    ...Actions_specialCategories(['getAllSpecialCategories']),
    ...Actions_reatailCategories([
      'getAllRetailCategories',
      'getRetailCategoryBySlug',
      'getCategoryColors',
    ]),
    ...Mutations_retailCategories([
      'SET_DEFAULT_CATEGORY',
      'CLEAN_SELECTED_CATEGORY',
    ]),
    handleSliderChange: debounce(function (newRange) {
      const [minPrice, maxPrice] = newRange
      this.getAllProductsWithFilterPrice({ min: minPrice, max: maxPrice })
    }, 300), // Задержка 300 мс
    selectItem(item) {
      this.setSelectedProduct(item)
      this.$router.push({ name: 'Product', params: { id: item?.id } })
    },
    filterBySpecial(category) {
      const categoryId = this.$route.query.categoryId
      const specialId = category?.id

      this.$router.push({
        name: 'Categories',
        query: { categoryId, specialId },
      })
    },
    addToCart(item) {
      const copy = cloneDeep(item)
      copy.colors = copy.colors[0]
      copy.colors.sizes = [copy.colors.sizes[0].value]
      this.addCartItem(copy)
      this.toggleSideCart(true)
    },
    filterByColor(color) {
      const isSpecialCategory = this.$route.query.specialId
      const type = isSpecialCategory ? 'special' : 'regular'

      this.colorFilterBy = color.name
      const data = { color, type }

      this.setProductByColor(data)
      window.scrollTo(0, 0)
    },
    goToProduct(product) {
      const isSpecialCategory = this.$route.query.specialId
      if (isSpecialCategory) {
        this.$router.push({
          name: 'Special-product',
          params: { id: product.product ? product.product.slug : product.slug },
        })
        setSelectedProduct(product.product)
      } else {
        this.$router.push({
          name: 'Product',
          params: { id: product.product ? product.product.slug : product.slug },
        })
        setSelectedProduct(product.product)
      }
    },
    setCategories(title, category, categoryId) {
      const id = this.$route.query.categoryId
      if (id === categoryId) {
        return true
      } else {
        this.CLEAN_SELECTED_CATEGORY()
        this.activeCategory = title
        this.$router.push({
          name: 'Categories',
          params: { url: category.url },
          query: { categoryId },
        })
      }
    },
  },
  async created() {
    await this.getAllRetailCategories()
    const slug = this.$route.path.split('/').pop()
    await this.getRetailCategoryBySlug(slug)
    await this.getAllSpecialCategories()
    await this.getCategoryColors()
    const isSpecialCategory = this.$route.query.specialId
    this.getMaxPrice()

    this.colors = !!isSpecialCategory
      ? this.categoryColors.artisan_colors
      : this.categoryColors.colors

    // this.getAllProducts()
    this.baseUrl = this.$store.state.auth.API_URL
    document.title =
      this.retailCategories.filter(
        (item) => item.id === Number(this.$route.query.categoryId)
      )[0]?.seo_title?.ru || 'Categories'
    // const currentCategory = this.$route.query.category
    // if (!currentCategory) {
    //   this.$router.replace({
    //     query: { category: this.retailCategories[0]?.url },
    //   })
    //   this.productByCatigory({ id: this.retailCategories[0]?.id })
    // }
    // const category = this.retailCategories.find(
    //   (item) => item.url === currentCategory
    // )
    const id = this.$route.query.categoryId
    const initialCategory = this.retailCategories?.find(
      (item) => item?.id === id
    )
    // this.productByCatigory({ id })

    if (initialCategory) {
      this.SET_DEFAULT_CATEGORY(initialCategory)
    }

    if (isSpecialCategory) {
      await this.specialProductByCategory({ id: isSpecialCategory })

      this.banner = this.specialCategories.find(
        (item) => Number(item.id) === Number(isSpecialCategory)
      )
    } else {
      this.productByCatigory({ id })
    }
  },
}
</script>
<style lang="scss">
.no-items {
  font-size: xx-large;
  text-align: center;
}
.options-span {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: normal;
  letter-spacing: 0.07em;
  text-transform: capitalize;
  color: #000000;
  text-decoration: none !important;
  height: 14px;
  transition: padding-left 0.5s ease;
}

.option-div {
  padding: 3px 0px;
  border-bottom: 1px solid rgba(196, 196, 196, 0.15);
}
.option-div:hover {
  .options-span {
    padding-left: 10px;
    color: rgb(62, 62, 252);
  }
}
.icon-title {
  i {
    width: 18px;
    height: 18px;
    font-size: 12px;
  }
}
i.v-icon.notranslate.mdi.mdi-chevron-down.theme--light {
  width: 18px;
  height: 18px;
  font-size: 12px;
}
.categories {
  .v-slider__thumb {
    border-radius: unset;
    width: 3px;
    left: -1.5px;
    &:before {
      content: none;
    }
  }
  .v-slider__thumb-label {
    height: 10px !important;
    width: 10px !important;
    background-color: unset !important;
    color: black;
    border-color: unset !important;
  }
  .v-expansion-panel-content__wrap {
    padding: 8px 8px 8px 16px;
  }
  .bordered {
    .v-badge__badge {
      border: 1px solid;
      border-color: #e1e1e1 !important;
    }
  }
  .sort {
    .v-btn__content {
      font-size: 0.875rem;
      color: #666666;
      font-weight: 500;
      padding: 2px 14px 2px 10px;
      letter-spacing: normal;
    }
  }
}
.sort__menu {
  box-shadow: unset !important;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
</style>
<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
  display: block;
}
.p_items {
  & > div {
    border: 1px solid #f2f2f2;
    .p_text {
      border-top: 1px solid #f2f2f2;
    }
  }
}
.v-btn {
  text-transform: unset;
}

.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: unset;
}
.v-expansion-panel-header {
  font-size: 0.875rem;
  color: #666666;
  font-weight: 500;
  padding-left: 8%;
  // min-height: unset;
}
.filter_header {
  .f_items.active a {
    color: #666666;
    font-weight: 700;
  }
  .f_items {
    cursor: pointer !important;
  }
  .f_items a {
    font-weight: 500;
    height: 100%;
  }
}
.v-menu__content {
  background-color: #fff;
}
.size-filter {
  span {
    font-size: 14px;
    color: #666666;
    font-weight: 500;
  }
}
.sort_by {
  font-size: 0.875rem;
  color: #a5a5a5;
  font-weight: 500;
  padding-right: 10px;
}
main.container.categories {
  padding-top: 0;
  margin-top: 0;
}
main.container.categories {
  margin-top: 84px !important;
}
.p17 {
  padding: 14px 0;
}
.slider-category {
  padding-left: 230px;
}
</style>
